import Vue from 'vue'
import Vuex from 'vuex'
import { io } from 'socket.io-client'
//const socket = io('http://192.168.0.105:5555/')
//const socket = io('https://vlera-art-api.vercel.app')
const socket = null
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    socketio: socket,
    isDrawerOpen: true,
  },
  mutations: {},
  actions: {},
  modules: {},
})
