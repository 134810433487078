<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          color="accent"
          size="40px"
          class="white--text font-weight-medium me-3"
          v-bind="attrs"
          v-on="on"
        >
          <span class="text-base">{{ getUserName.substring(0, 2) }}</span>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar color="accent" size="40" class="white--text font-weight-medium me-3">
            <span class="text-base">{{ getUserName.substring(0, 2) }}</span>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align: middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ username }}
          </span>
          <small class="text--disabled text-capitalize"
            >{{ getUserName }} - {{ getUserRole }}</small
          >
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Logout -->
      <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-on:click="logout()">Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from "@mdi/js";
import { getAuth } from "firebase/auth";

export default {
  data() {
    return {
      username: "",
    };
  },
  computed: {
    getUserRole() {
      return JSON.parse(localStorage.getItem("user")).role;
    },
    getUserName() {
      return JSON.parse(localStorage.getItem("user")).name;
    },
  },
  methods: {
    toggleDrawer: function () {
      this.$store.state.isDrawerOpen = !this.$store.state.isDrawerOpen;
    },
    logout: function (_) {
      this.$cookies.remove("login");
      localStorage.removeItem("user");
      getAuth().signOut();
      this.$router.push({ name: "login" });
    },
  },
  mounted() {
    this.username = "";
  },
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    };
  },
};
</script>
<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
