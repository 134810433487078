var albanian_localization = {
  'Monthly Statistics': 'Monthly Statistics',
  'Yearly Statistics': 'Yearly Statistics',
  'My Drivers': 'My Drivers',
  'Active Orders': 'Active Orders',
  Order: 'Order',
  'Total Profit': 'Total Profit',
  'Sales by Magazines': 'Sales by Magazines',
  Sales: 'Sales',
  'Products Sold': 'Products Sold',
  Profit: 'Profit',
  Taxes: 'Taxes',
  'Sales by Products': 'Sales by Products',
  Orders: 'Orders',
  Sales: 'Sales',
  Dashboard: 'Dashboard',
  Stocks: 'Stocks',
  Transfers: 'Transfers',
  Clients: 'Clients',
  Checkins: 'Checkins',
  Users: 'Users',
  Magazines: 'Magazines',
  Suppliers: 'Suppliers',
  Measurements: 'Measurements',
  Products: 'Products',
  'New Checkin': 'New Checkin',
  Stocks: 'Stocks',
  Product: 'Product',
  Magazine: 'Magazine',
  Amount: 'Amount',
  'New Checkin': 'New Checkin',
  Date: 'Date',
  Magazine: 'Magazine',
  Supplier: 'Supplier',
  'Received Stocks': 'Received Stocks',
  add: 'add',
  'Quantity Price': 'Quantity Price',
  Amount: 'Amount',
  Discount: 'Discount',
  Tax: 'Tax',
  Total: 'Total',
  Actions: 'Actions',
  'Sub Total': 'Sub Total',
  'Total Discount': 'Total Discount',
  'Total Tax': 'Total Tax',
  'Grand Total': 'Grand Total',
  Submit: 'Submit',
  'Supplier is Paid': 'Supplier is Paid',
  'New Order': 'New Order',
  Filter: 'Filter',
  'Are you sure want to delete Order': 'Are you sure want to delete Order',
  Cancel: 'Cancel',
  OK: 'OK',
  'Filter Orders': 'Filter Orders',
  'Order Status': 'Order Status',
  'Sales Post': 'Sales Post',
  'Created Date': 'Created Date',
  Reset: 'Reset',
  'Order Number': 'Order Number',
  Deadline: 'Deadline',
  'Customer Name': 'Customer Name',
  'Order Status': 'Order Status',
  'Total Price': 'Total Price',
  Actions: 'Actions',
  'Client Info': 'Client Info',
  Search: 'Search',
  'Client ID': 'Client ID',
  'Client Name': 'Client Name',
  'Phone Number': 'Phone Number',
  Address: 'Address',
  'Order Info': 'Order Info',
  Deadline: 'Deadline',
  'Sales Post': 'Sales Post',
  'Delivery Fee': 'Delivery Fee',
  Designers: 'Designers',
  Stocks: 'Stocks',
  add: 'add',
  Product: 'Product',
  'Quantity Price': 'Quantity Price',
  Amount: 'Amount',
  Actions: 'Actions',
  'Price of one': 'Price of one',
  available: 'available',
  'Not enough in Stock': 'Not enough in Stock',
  Services: 'Services',
  'Service Name': 'Service Name',
  Text: 'Text',
  Notes: 'Notes',
  Price: 'Price',
  'Service Name': 'Service Name',
  'Service Price': 'Service Price',
  Submit: 'Submit',
  'New Client': 'New Client',
  Production: 'Production',
  Client: 'Client',
  'Order Info': 'Order Info',
  'Created at': 'Created at',
  'Created By': 'Created By',
  'Driver Picked up at': 'Driver Picked up at',
  'Driver Delivered at': 'Driver Delivered at',
  Status: 'Status',
  Stocks: 'Stocks',
  Processing: 'Processing',
  Returned: 'Returned',
  'Waiting for a Driver': 'Waiting for a Driver',
  'Client Picking Up': 'Client Picking Up',
  'Company Delivering': 'Company Delivering',
  'Driver Picking up': 'Driver Picking up',
  'Driver Delivering': 'Driver Delivering',
  Completed: 'Completed',
  'Phone Number': 'Phone Number',
  Address: 'Address',
  Name: 'Name',
  'Phone Number': 'Phone Number',
  'Search for Client': 'Search for Client',
  'Search by': 'Search by',
  Text: 'Text',
  Search: 'Search',
  SELECT: 'SELECT',
  'New Client': 'New Client',
  Transfers: 'Transfers',
  Transfer: 'Transfer',
  'Are you sure want to delete Transfer': 'Are you sure want to delete Transfer',
  'Created Date': 'Created Date',
  'From Magazine': 'From Magazine',
  'To Magazine': 'To Magazine',
  'Transfer Status': 'Transfer Status',
  'New Transfer': 'New Transfer',
  'Transfer Info': 'Transfer Info',
  Driver: 'Driver',
  'change Transfer Status': 'change Transfer Status',
  Clients: 'Clients',
  Checkins: 'Checkins',
  Checkin: 'Checkin',
  'Are you sure want to delete Checkin': 'Are you sure want to delete Checkin',
  'Supplier Name': 'Supplier Name',
  'Payment Status': 'Payment Status',
  'Total Payment': 'Total Payment',
  Paid: 'Paid',
  'Not Paid': 'Not Paid',
  'Supplier Info': 'Supplier Info',
  'Checkin Info': 'Checkin Info',
  'Received at': 'Received at',
  'New User': 'New User',
  'Are you sure want to delete this account? this process cannot be undone':
    'Are you sure want to delete this account? this process cannot be undone',
  Password: 'Password',
  Delete: 'Delete',
  Insert: 'Insert',
  driver: 'driver',
  manager: 'manager',
  'sales agent': 'sales agent',
  designer: 'designer',
  production: 'production',
  Email: 'Email',
  Role: 'Role',
  Approved: 'Approved',
  'Not Approved': 'Not Approved',
  'New Product': 'New Product',
  'Are you sure want to delete this item': 'Are you sure want to delete this item',
  'Buy Price': 'Buy Price',
  'Sell Price': 'Sell Price',
  Type: 'Type',
  'product name': 'product name',
  'Buy Price (no Tax)': 'Buy Price (no Tax)',
  'Sell Price (no Tax)': 'Sell Price (no Tax)',
  Measurement: 'Measurement',
  'Single Product': 'Single Product',
  'Mixed Product': 'Mixed Product',
  'New Magazine': 'New Magazine',
  'Magazine Name': 'Magazine Name',
  'Magazine Location on Map': 'Magazine Location on Map',
  Suppliers: 'Suppliers',
  'Edit Supplier': 'Edit Supplier',
  SAVE: 'SAVE',
  Measurements: 'Measurements',
  'Measurement Name': 'Measurement Name',
  'Sales Post is not chosen': 'Sales Post is not chosen',
  Origin: 'Origin',
  'Return reason': 'Return reason',
  'Product SKU': 'Product SKU',
  Expenses: 'Expenses',
}

exports.albanian_localization = albanian_localization
