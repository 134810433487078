import VueCompositionAPI from '@vue/composition-api'
import Vue from 'vue'
import moment from 'moment'
import DatetimePicker from 'vuetify-datetime-picker'
import { LoadingPlugin } from 'vuetify-loading-overlay'
import Loading from 'vuetify-loading-overlay/src/api'
import albanian_localization from '../plugins/albanian_localization'
Vue.use(require('https'))
Vue.use(require('vue-cookies'))

Vue.mixin({
  methods: {
    timeAgo: value => moment(value).fromNow(),
    formatDate: value => moment(value).format('DD-MM-YYYY hh:mm'),
    nFormatter: num => {
      const lookup = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'G' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' },
      ]
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
      var item = lookup
        .slice()
        .reverse()
        .find(function (item) {
          return num >= item.value
        })
      return item ? (num / item.value).toFixed(1).replace(rx, '$1') + item.symbol : '0'
    },
    textToSalesPost: text => {
      switch (text) {
        case 'delivery_company':
          return 'Addex'
        case 'client_picks_up':
          return 'Shop Peshkopi'
        default:
          return text
      }
    },
    salesPostToText: text => {
      switch (text) {
        case 'Addex':
          return 'delivery_company'
        case 'Shop Peshkopi':
          return 'client_picks_up'
        default:
          return text
      }
    },
    orderStatusToColor: status => {
      switch (status) {
        case 'waiting_for_a_driver':
          return 'error'
        case 'choose_agents':
          return 'error'
        case 'liquidating':
          return 'info'
        case 'driver_liquidating':
          return 'info'

        case 'returned':
          return 'warning'
        case 'choose_devpos':
          return 'warning'
        case 'driver_picking_up':
          return 'info'
        case 'driver_delivering':
          return 'info'
        case 'completed':
          return 'success'
        case 'canceled':
          return 'error'
        case 'designing':
          return 'warning'
        case 'production':
          return 'warning'
        case 'company_delivering':
          return 'info'
        case 'client_picking_up':
          return 'info'
        case 'refused':
          return 'error'
        case 'delivered':
          return 'success'
        default:
          return 'primary'
      }
    },
    orderStatusToText: status => {
      switch (status) {
        case 'waiting_for_a_driver':
          return 'Waiting for a Driver'
        case 'choose_agents':
          return 'Choose Agents'
        case 'liquidating':
          return 'Liquidating'
        case 'driver_liquidating':
          return 'Driver Liquidating'
        case 'choose_devpos':
          return 'Choose Devpos'

        case 'driver_picking_up':
          return 'Driver Picking Up'
        case 'driver_delivering':
          return 'Delivering'
        case 'completed':
          return 'Completed'
        case 'delivered':
          return 'Delivered'
        case 'canceled':
          return 'Canceled'
        case 'company_delivering':
          return 'Company Delivering'
        case 'client_picking_up':
          return 'Client Picking Up'
        case 'designing':
          return 'Designing'
        case 'production':
          return 'Production'
        case 'returned':
          return 'Returned'
        case 'refused':
          return 'Refused'
      }
      return
    },
    translate: text => {
      if (albanian_localization['albanian_localization'][text] !== undefined) {
        return `${albanian_localization['albanian_localization'][text]}`
      }
      return `${text}`
    },
  },
})

Vue.use(DatetimePicker)
Vue.use(VueCompositionAPI)
Vue.use(require('vue-moment'))
