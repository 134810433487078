import axios from 'axios'
import Vue from 'vue'
import VueCookies from 'vue-cookies'

var baseUrl = 'https://vleraart.online/api'
//var baseUrl = 'http://127.0.0.1:5555/'
export async function emptyDatabase() {
  var data = await axios({
    url: `${baseUrl}/emptyDatabase?id_token=${VueCookies.get('login')}`,
  })
  return data
}
export async function getBackupDatabaseURL() {
  var url = `${baseUrl}/backupDatabase`
  return url
}
export function getDownloadServiceFileUrl(order_id, filename) {
  var url = `${baseUrl}/get_service_file?order_id=${order_id}&filename=${filename}`
  return url
}
export async function getOrders(sales_post = 'null', dateRange = '', selected_liquidation = null) {
  var timestamp = new Date().getTime()
  var data = await axios({
    url: `${baseUrl}/get_orders?timestamp=${timestamp}&sales_post=${sales_post}&dateRange=${dateRange}&selected_liquidation=${selected_liquidation}&id_token=${VueCookies.get(
      'login',
    )}`,
  })
  return data
}
export async function getStocksByDate(from_date, to_date) {
  var data = await axios({
    url: `${baseUrl}/get_stocks_by_date?from_date=${from_date}&to_date=${to_date}&id_token=${VueCookies.get('login')}`,
  })
  return data
}
export async function getExpenses(dateRange) {
  var data = await axios({
    url: `${baseUrl}/get_expenses?dateRange=${dateRange}&id_token=${VueCookies.get('login')}`,
  })
  return data
}
export async function getDesignerOrders(designerId, orderStatus = '"designing"', dateRange = '') {
  var data = await axios({
    url: `${baseUrl}/get_designer_orders?designer_id=${designerId}&dateRange=${dateRange}&orderStatus=${orderStatus}&id_token=${VueCookies.get(
      'login',
    )}`,
  })
  return data
}
export async function getSalesOrders(salesAgentId, dateRange = '', type = 'all') {
  var data = await axios({
    url: `${baseUrl}/get_sales_agent_orders?sales_agent_id=${salesAgentId}&dateRange=${dateRange}&type=${type}&id_token=${VueCookies.get(
      'login',
    )}`,
  })
  return data
}
export async function getDriverOrders(id, dateRange) {
  var data = await axios({
    url: `${baseUrl}/get_driver_orders?id=${id}&dateRange=${dateRange}&id_token=${VueCookies.get('login')}`,
  })
  return data
}
export async function getProductionOrders(productionId, orderStatus = '"production","returned"', dateRange = '') {
  var data = await axios({
    url: `${baseUrl}/get_production_orders?production_id=${productionId}&dateRange=${dateRange}&orderStatus=${orderStatus}&id_token=${VueCookies.get(
      'login',
    )}`,
  })
  return data
}
export async function toggleOrderDesigner(designerId, order_id, state, sales_post, delayReason) {
  var data = await axios({
    url: `${baseUrl}/toggle_order_designer?designer_id=${designerId}&order_id=${order_id}&state=${state}&sales_post=${sales_post}&delay_reason=${delayReason}&id_token=${VueCookies.get(
      'login',
    )}`,
  })
  return data
}
export async function proceedOrderFromProduction(order_id, sales_post) {
  var data = await axios({
    url: `${baseUrl}/proceed_order_from_production?order_id=${order_id}&sales_post=${sales_post}&id_token=${VueCookies.get(
      'login',
    )}`,
  })
  return data
}
export async function sendOrderToAdex(order_id, weight, package_type, package_quantity, devpos) {
  var data = await axios({
    url: `${baseUrl}/send_order_to_adex?order_id=${order_id}&weight=${weight}&package_type=${package_type}&package_quantity=${package_quantity}&devpos=${devpos}&id_token=${VueCookies.get(
      'login',
    )}`,
  })
  return data
}
export async function openOrderInvoice(order_id) {
  var url = `${baseUrl}/get_invoice?order_id=${order_id}`

  return url
}
export async function getTransfers(dateRange) {
  var response = await axios({
    url: `${baseUrl}/get_transfers?dateRange=${dateRange}&id_token=${VueCookies.get('login')}`,
  })
  return response.data
}
export async function getOrderInfo(order_id, isOrderEdit = false) {
  var response = await axios({
    url: `${baseUrl}/get_order_info?order_id=${order_id}&isOrderEdit=${isOrderEdit}&id_token=${VueCookies.get(
      'login',
    )}`,
  })
  return response.data
}
export async function getUsers() {
  var response = await axios({ url: `${baseUrl}/get_users?id_token=${VueCookies.get('login')}` })
  return response.data
}
export async function getMagazines() {
  var response = await axios({ url: `${baseUrl}/get_magazines?id_token=${VueCookies.get('login')}` })
  return response.data
}
export async function getCities() {
  var response = await axios({ url: `${baseUrl}/get_cities?id_token=${VueCookies.get('login')}` })
  return response.data
}
export async function getStates() {
  var response = await axios({ url: `${baseUrl}/get_states?id_token=${VueCookies.get('login')}` })
  return response.data
}
export async function getExpenseTypes() {
  var response = await axios({ url: `${baseUrl}/get_expense_types?id_token=${VueCookies.get('login')}` })
  return response.data
}
export async function getProfitLoss(dateRange) {
  var response = await axios({
    url: `${baseUrl}/get_profit_loss?dateRange=${dateRange}&id_token=${VueCookies.get('login')}`,
  })
  return response.data
}
export async function getSuppliers() {
  var response = await axios({ url: `${baseUrl}/get_suppliers?id_token=${VueCookies.get('login')}` })
  return response.data
}
export async function getOrigins() {
  var response = await axios({ url: `${baseUrl}/get_origins?id_token=${VueCookies.get('login')}` })
  return response.data
}
export async function getPaymentMethods() {
  var response = await axios({ url: `${baseUrl}/get_payment_methods?id_token=${VueCookies.get('login')}` })
  return response.data
}
export async function insertNewOrderImage(file = null, orderId = 0) {
  const formData = new FormData()
  formData.append('file', file)
  var response = await axios.post(
    `${baseUrl}/upload_order_image?order_id=${orderId}&id_token=${VueCookies.get('login')}`,
    formData,
  )
  return { response: 'data', data: response.data }
}
export async function uploadServiceImage(file = null, orderId = 0) {
  const formData = new FormData()
  formData.append('file', file)
  var response = await axios.post(
    `${baseUrl}/upload_service_image?order_id=${orderId}&id_token=${VueCookies.get('login')}`,
    formData,
  )
  return { response: 'data', data: response.data }
}
export async function uploadDatabase(file) {
  const formData = new FormData()
  formData.append('file', file)
  console.log(formData)
  //var url=`https://vleraartapi.invictsoft.tech/restoreDatabase`

  var url = `${baseUrl}/restoreDatabase`
  await axios.post(url, formData)
}
export async function insertNeworder(
  current_date,
  clientName,
  salesPost,
  magazineId,
  phoneNumber,
  email,
  address,
  lat,
  lng,
  totalPrice,
  deadline,
  driverId,
  deliveryFee,
  stocks,
  services,
  profit,
  files,
  designers,
  productions,
  client_id,
  created_by_user_id,
  origin,
  idNumber,
  idType,
  city_id,
  area_id,
  old_client_id,
  payment_method,
  serviceFiles,
  state_id,
  zip_code,
  currency_id,
  payment_amount,
) {
  const formData = new FormData()
  files.forEach(element => {
    formData.append(element.name, element)
  })
  serviceFiles.forEach(element => {
    formData.append(element.name, element)
  })
  formData.append('serviceFiles', serviceFiles)
  formData.append('orderImages', files)
  formData.append('stocks', JSON.stringify(stocks))
  formData.append('services', JSON.stringify(services))

  var url = `${baseUrl}/insert_new_order?payment_amount=${payment_amount}&currency_id=${currency_id}&zip_code=${zip_code}&state_id=${state_id}&date=${current_date}&old_client_id=${old_client_id}&idNumber=${idNumber}&idType=${idType}&city_id=${city_id}&area_id=${area_id}&created_by_user_id=${created_by_user_id}&client_name=${clientName}&sales_post=${salesPost}&magazine_id=${magazineId}&client_id=${client_id}&phone_number=${phoneNumber}&email=${email}&address_note=${address}&lat=${lat}&lng=${lng}&total_price=${totalPrice}&deadline=${deadline}${
    driverId == null ? '' : '&' + driverId
  }&delivery_fee=${deliveryFee}&designers=${JSON.stringify(designers)}&production=${JSON.stringify(
    productions,
  )}&origin=${origin}&profit=${profit}&payment_method=${payment_method}&id_token=${VueCookies.get('login')}`

  var data = await axios.post(url, formData)
  return data
}
export async function editOrder(
  clientName,
  salesPost,
  magazineId,
  phoneNumber,
  email,
  address,
  lat,
  lng,
  totalPrice,
  deadline,
  driverId,
  deliveryFee,
  stocks,
  services,
  profit,
  files,
  designers,
  productions,
  client_id,
  created_by_user_id,
  origin,
  idNumber,
  idType,
  town,
  old_client_id,
  payment_method,
  order_id,
  state_id,
  zip_code,
  currency_id,
  payment_amount,
  city_id,
  area_id,
) {
  console.log(files)
  const formData = new FormData()
  files.forEach(element => {
    formData.append(element.name, element)
  })
  formData.append('files', files)
  formData.append('stocks', JSON.stringify(stocks))
  formData.append('services', JSON.stringify(services))

  var url = `${baseUrl}/edit_order?city_id=${city_id}&area_id=${area_id}&state_id=${state_id}&zip_code=${zip_code}&currency_id=${currency_id}&payment_amount=${payment_amount}&order_id=${order_id}&old_client_id=${old_client_id}&idNumber=${idNumber}&idType=${idType}&town=${town}&created_by_user_id=${created_by_user_id}&client_name=${clientName}&sales_post=${salesPost}&magazine_id=${magazineId}&client_id=${client_id}&phone_number=${phoneNumber}&email=${email}&address_note=${address}&lat=${lat}&lng=${lng}&total_price=${totalPrice}&deadline=${deadline}${
    driverId == null ? '' : '&' + driverId
  }&delivery_fee=${deliveryFee}&designers=${JSON.stringify(designers)}&production=${JSON.stringify(
    productions,
  )}&origin=${origin}&profit=${profit}&payment_method=${payment_method}&id_token=${VueCookies.get('login')}`

  var data = await axios.post(url, formData)
  return data
}
export async function insertNewTransfer(stocks, magazine_from_id, magazine_to_id, date) {
  var data = await axios({
    url: `${baseUrl}/insert_new_transfer?&stocks=${JSON.stringify(
      stocks,
    )}&magazine_from_id=${magazine_from_id}&magazine_to_id=${magazine_to_id}&date=${date}&id_token=${VueCookies.get(
      'login',
    )}`,
  })
  return data
}

export async function editSupplier(id, name, email, phone) {
  var data = await axios({
    url: `${baseUrl}/edit_supplier?id=${id}&name=${name}&email=${email}&phone_number=${phone}&id_token=${VueCookies.get(
      'login',
    )}`,
  })
  return data
}
export async function editOrigin(id, name) {
  var data = await axios({
    url: `${baseUrl}/edit_origin?id=${id}&name=${name}&id_token=${VueCookies.get('login')}`,
  })
  return data
}
export async function editPaymentMethod(id, text, devpos_id) {
  var data = await axios({
    url: `${baseUrl}/edit_payment_method?id=${id}&text=${text}&devpos_id=${devpos_id}&id_token=${VueCookies.get(
      'login',
    )}`,
  })
  return data
}
export async function removeOrderImage(imageId) {
  var data = await axios({
    url: `${baseUrl}/remove_order_image?id=${imageId}&id_token=${VueCookies.get('login')}`,
  })
  return data
}
export async function deleteServiceImage(id) {
  var data = await axios({
    url: `${baseUrl}/delete_service_file?id=${id}&id_token=${VueCookies.get('login')}`,
  })
  return data
}
export async function deleteOrder(orderId) {
  var data = await axios({
    url: `${baseUrl}/delete_order?id=${orderId}&id_token=${VueCookies.get('login')}`,
  })
  return data
}
export async function deleteExpense(expenseId) {
  var data = await axios({
    url: `${baseUrl}/delete_expense?id=${expenseId}&id_token=${VueCookies.get('login')}`,
  })
  return data
}
export async function deleteTransfer(transferId) {
  var data = await axios({
    url: `${baseUrl}/delete_transfer?id=${transferId}&id_token=${VueCookies.get('login')}`,
  })
  return data
}

export async function assignTransferToDriver(transferId, driverId) {
  var data = await axios({
    url: `${baseUrl}/assign_transfer_to_driver?transfer_id=${transferId}&driver_id=${driverId}&id_token=${VueCookies.get(
      'login',
    )}`,
  })
  return data
}
export async function changeTransferStatus(transferId, status) {
  var data = await axios({
    url: `${baseUrl}/change_transfer_status?transfer_id=${transferId}&status=${status}&id_token=${VueCookies.get(
      'login',
    )}`,
  })
  return data
}
export async function changeUserRole(userId, role) {
  var data = await axios({
    url: `${baseUrl}/change_user_role?user_id=${userId}&role=${role}&id_token=${VueCookies.get('login')}`,
  })
  return data
}
export async function changeOrderStatus(orderId, order_status) {
  var data = await axios({
    url: `${baseUrl}/change_order_status?order_id=${orderId}&order_status=${order_status}&id_token=${VueCookies.get(
      'login',
    )}`,
  })
  return data
}
export async function returnOrder(orderId, reason) {
  var data = await axios({
    url: `${baseUrl}/return_order?order_id=${orderId}&reason=${reason}&id_token=${VueCookies.get('login')}`,
  })
  return data
}
export async function devpos_chosen(orderId, devpos, check_devpos = false) {
  var data = await axios({
    url: `${baseUrl}/devpos_chosen?order_id=${orderId}&devpos=${devpos}&check_devpos=${check_devpos}&id_token=${VueCookies.get(
      'login',
    )}`,
  })
  return data
}

export async function changeOrderDesigners(orderId, new_designers, old_designers) {
  var data = await axios({
    url: `${baseUrl}/change_order_designers?order_id=${orderId}&new_designers=${JSON.stringify(
      new_designers,
    )}&old_designers=${JSON.stringify(old_designers)}&id_token=${VueCookies.get('login')}`,
  })
  return data
}
export async function changeOrderProductions(orderId, new_productions, old_productions) {
  var data = await axios({
    url: `${baseUrl}/change_order_productions?order_id=${orderId}&new_productions=${JSON.stringify(
      new_productions,
    )}&old_productions=${JSON.stringify(old_productions)}&id_token=${VueCookies.get('login')}`,
  })
  return data
}
export async function changeOrderSalesPost(orderId, salesPost) {
  var data = await axios({
    url: `${baseUrl}/change_order_sales_post?order_id=${orderId}&sales_post=${salesPost}&id_token=${VueCookies.get(
      'login',
    )}`,
  })
  return data
}
export async function toggleUserActivation(userId, activation) {
  var data = await axios({
    url: `${baseUrl}/toggle_user_activation?user_id=${userId}&activation=${activation}&id_token=${VueCookies.get(
      'login',
    )}`,
  })
  return data
}

export async function checkToken() {
  var response = await axios({ url: `${baseUrl}/check_token?id_token=${VueCookies.get('login')}` })
  return response.data
}
export async function getDashboardData() {
  var response = await axios({ url: `${baseUrl}/get_dashboard_data?id_token=${VueCookies.get('login')}` })
  return response.data
}
export async function getCurrency() {
  var response = await axios({ url: `${baseUrl}/get_currency?id_token=${VueCookies.get('login')}` })
  return response.data
}
export async function getClients(dateRange) {
  var response = await axios({
    url: `${baseUrl}/get_clients?dateRange=${dateRange}&id_token=${VueCookies.get('login')}`,
  })
  return response.data
}
export async function getEmployeesReport(dateRange) {
  var response = await axios({
    url: `${baseUrl}/get_employees_report?dateRange=${dateRange}&id_token=${VueCookies.get('login')}`,
  })
  return response.data
}
export async function getInventoryAdjustments(dateRange) {
  var response = await axios({
    url: `${baseUrl}/get_inventory_adjustments?dateRange=${dateRange}&id_token=${VueCookies.get('login')}`,
  })
  return response.data
}
export async function getStocks() {
  var response = await axios({ url: `${baseUrl}/get_stocks?id_token=${VueCookies.get('login')}` })
  return response.data
}

export async function getCheckins(dateRange) {
  var response = await axios({
    url: `${baseUrl}/get_checkins?dateRange=${dateRange}&id_token=${VueCookies.get('login')}`,
  })
  return response.data
}
export async function getCompletedOrders(
  sales_post,
  dateRange,
  selected_designer,
  selected_production,
  selected_agent,
  delayed_orders,
  selected_magazine,
  origin,
  payment_method,
  service,
  product,
) {
  var response = await axios({
    url: `${baseUrl}/get_completed_orders?sales_post=${sales_post}&dateRange=${dateRange}&selected_designer=${selected_designer}&selected_production=${selected_production}&selected_agent=${selected_agent}&delayed_orders=${delayed_orders}&selected_magazine=${selected_magazine}&origin=${origin}&payment_method=${payment_method}&service=${service}&product=${product}&id_token=${VueCookies.get(
      'login',
    )}`,
  })
  return response.data
}
export async function getProducts() {
  var response = await axios({ url: `${baseUrl}/get_products?id_token=${VueCookies.get('login')}` })
  return response.data
}

export async function getMeasurements() {
  var response = await axios({ url: `${baseUrl}/get_measurements?id_token=${VueCookies.get('login')}` })
  return response.data
}
export async function getCategories() {
  var response = await axios({ url: `${baseUrl}/get_categories?id_token=${VueCookies.get('login')}` })
  return response.data
}
export async function getMachines() {
  var response = await axios({ url: `${baseUrl}/get_machines?id_token=${VueCookies.get('login')}` })
  return response.data
}
export async function getApprovedDrivers() {
  var response = await axios({ url: `${baseUrl}/get_approved_drivers?id_token=${VueCookies.get('login')}` })
  return response.data
}
export async function getApprovedUsers() {
  var response = await axios({ url: `${baseUrl}/get_approved_users?id_token=${VueCookies.get('login')}` })
  return response.data
}
export async function deleteUser(id) {
  var response = await axios({ url: `${baseUrl}/delete_user?id=${id}&id_token=${VueCookies.get('login')}` })
  return response.data
}
export async function editMagazine(id, name, address, town, lat, lng, phone_number, allow_stock_override) {
  var response = await axios({
    url: `${baseUrl}/edit_magazine?id=${id}&address=${address}&town=${town}&name=${name}&lat=${lat}&lng=${lng}&phone_number=${phone_number}&allow_stock_override=${allow_stock_override}&id_token=${VueCookies.get(
      'login',
    )}`,
  })
  return response.data
}
export async function deleteMagazine(id) {
  var response = await axios({ url: `${baseUrl}/delete_magazine?id=${id}&id_token=${VueCookies.get('login')}` })
  return response.data
}
export async function deleteExpenseType(id) {
  var response = await axios({ url: `${baseUrl}/delete_expense_type?id=${id}&id_token=${VueCookies.get('login')}` })
  return response.data
}
export async function newMagazine(inventoryname, inventoryaddress, town, lat, lng, phone_number) {
  var response = await axios({
    url: `${baseUrl}/insert_new_magazine?address=${inventoryaddress}&name=${inventoryname}&town=${town}&lat=${lat}&lng=${lng}&phone_number=${phone_number}&id_token=${VueCookies.get(
      'login',
    )}`,
  })
  return response.data
}
export async function newExpenseType(inventoryname) {
  var response = await axios({
    url: `${baseUrl}/insert_new_expense_type?name=${inventoryname}&id_token=${VueCookies.get('login')}`,
  })
  return response.data
}
export async function editExpenseType(id, inventoryname) {
  var response = await axios({
    url: `${baseUrl}/edit_expense_type?id=${id}&name=${inventoryname}&id_token=${VueCookies.get('login')}`,
  })
  return response.data
}
export async function newExpense(expense_type_id, note, date, amount) {
  var response = await axios({
    url: `${baseUrl}/insert_new_expense?note=${note}&expense_type_id=${expense_type_id}&date=${date}&amount=${amount}&id_token=${VueCookies.get(
      'login',
    )}`,
  })
  return response.data
}
export async function newClient(
  name,
  address,
  lat,
  lng,
  phone,
  idNumber,
  idType,
  city_id,
  area_id,
  state_id,
  zip_code,
) {
  var response = await axios({
    url: `${baseUrl}/insert_new_client?state_id=${state_id}&zip_code=${zip_code}&address=${address}&name=${name}&lat=${lat}&lng=${lng}&phone=${phone}&idNumber=${idNumber}&idType=${idType}&city_id=${city_id}&area_id=${area_id}&id_token=${VueCookies.get(
      'login',
    )}`,
  })
  return response.data
}
export async function editMeasurement(id, name) {
  var response = await axios({
    url: `${baseUrl}/edit_measurement?id=${id}&name=${name}&id_token=${VueCookies.get('login')}`,
  })
  return response.data
}
export async function deleteMeasurement(id, name) {
  var response = await axios({
    url: `${baseUrl}/delete_measurement?id=${id}&name=${name}&id_token=${VueCookies.get('login')}`,
  })
  return response.data
}
export async function deleteCategory(id, name) {
  var response = await axios({
    url: `${baseUrl}/delete_category?id=${id}&name=${name}&id_token=${VueCookies.get('login')}`,
  })
  return response.data
}
export async function deleteMachine(id, name) {
  var response = await axios({
    url: `${baseUrl}/edit_machine?id=${id}&name=${name}&id_token=${VueCookies.get('login')}`,
  })
  return response.data
}
export async function editCategory(id, name) {
  var response = await axios({
    url: `${baseUrl}/edit_category?id=${id}&name=${name}&id_token=${VueCookies.get('login')}`,
  })
  return response.data
}
export async function editMachine(id, name) {
  var response = await axios({
    url: `${baseUrl}/edit_machine?id=${id}&name=${name}&id_token=${VueCookies.get('login')}`,
  })
  return response.data
}
export async function newMeasurement(name) {
  var response = await axios({
    url: `${baseUrl}/insert_new_measurement?name=${name}&id_token=${VueCookies.get('login')}`,
  })
  return response.data
}
export async function newCategory(name) {
  var response = await axios({
    url: `${baseUrl}/insert_new_category?name=${name}&id_token=${VueCookies.get('login')}`,
  })
  return response.data
}
export async function newMachine(name) {
  var response = await axios({
    url: `${baseUrl}/insert_new_machine?name=${name}&id_token=${VueCookies.get('login')}`,
  })
  return response.data
}
export async function searchForClients(searchBy, text) {
  var response = await axios({
    url: `${baseUrl}/search_for_clients?search_by=${searchBy}&text=${text}&id_token=${VueCookies.get('login')}`,
  })
  return response.data
}
export async function searchForClientsById(text) {
  var response = await axios({
    url: `${baseUrl}/search_for_client_by_id?text=${text}&id_token=${VueCookies.get('login')}`,
  })
  return response.data
}
export async function newProduct(
  products,
  type,
  name,
  buy_price,
  sell_price,
  measurement_id,
  machine_id,
  category_id,
  sku,
  image,
) {
  const formData = new FormData()

  formData.append('name', name)
  formData.append('file', image)

  var response = await axios.post(
    `${baseUrl}/insert_new_product?products=${products}&type=${type}&name="${name}"&buy_price=${buy_price}&sell_price=${sell_price}&measurement_id=${measurement_id}&machine_id=${machine_id}&category_id=${category_id}&sku=${sku}&id_token=${VueCookies.get(
      'login',
    )}`,
    formData,
  )
  return response.data
}
export async function deleteProduct(id) {
  var data = await axios({
    url: `${baseUrl}/delete_product?id=${id}&id_token=${VueCookies.get('login')}`,
  })
  return data
}
export async function editProduct(
  id,
  products,
  type,
  name,
  buy_price,
  sell_price,
  measurement_id,
  sku,
  image,
  category_id,
  machine_id,
) {
  const formData = new FormData()

  formData.append('name', name)
  formData.append('file', image)

  var response = await axios.post(
    `${baseUrl}/edit_product?id=${id}&products=${products}&type=${type}&buy_price=${buy_price}&sku=${sku}&sell_price=${sell_price}&measurement_id=${measurement_id}&category_id=${category_id}&machine_id=${machine_id}&id_token=${VueCookies.get(
      'login',
    )}`,
    formData,
  )
  return response
}
export async function toggleSupplierPayment(id, state) {
  var response = await axios({
    url: `${baseUrl}/toggle_supplier_payment?id=${id}&state=${state}&id_token=${VueCookies.get('login')}`,
  })
  return response.data
}

export async function newCheckin(magazineId, supplierPaid, supplierId, totalPaid, date, stocks) {
  var data = await axios({
    url: `${baseUrl}/insert_new_checkin?magazine_id=${magazineId}&supplier_paid=${supplierPaid}&supplier_id=${supplierId}&total_paid=${totalPaid}&date=${date}&stocks=${stocks}&id_token=${VueCookies.get(
      'login',
    )}`,
  })
  return data
}
export async function adjustInventory(magazineId, note, date, stocks) {
  var data = await axios({
    url: `${baseUrl}/adjust_inventory?magazine_id=${magazineId}&note=${note}&date=${date}&stocks=${stocks}&id_token=${VueCookies.get(
      'login',
    )}`,
  })
  return data
}
export async function newSupplier(name, phone, email) {
  var response = await axios({
    url: `${baseUrl}/insert_new_supplier?name=${name}&phone_number=${phone}&email=${email}&id_token=${VueCookies.get(
      'login',
    )}`,
  })
  return response.data
}
export async function newPaymentMethod(text, devpos_id) {
  var response = await axios({
    url: `${baseUrl}/insert_new_payment_method?text=${text}&devpos_id=${devpos_id}&id_token=${VueCookies.get('login')}`,
  })
  return response.data
}
export async function newOrigin(name) {
  var response = await axios({
    url: `${baseUrl}/insert_new_origin?name=${name}&id_token=${VueCookies.get('login')}`,
  })
  return response.data
}
export async function deleteSupplier(id) {
  var data = await axios({
    url: `${baseUrl}/delete_supplier?id=${id}&id_token=${VueCookies.get('login')}`,
  })
  return data
}
export async function deleteOrigin(id) {
  var data = await axios({
    url: `${baseUrl}/delete_origin?id=${id}&id_token=${VueCookies.get('login')}`,
  })
  return data
}
export async function deletePaymentMethod(id) {
  var data = await axios({
    url: `${baseUrl}/delete_payment_method?id=${id}&id_token=${VueCookies.get('login')}`,
  })
  return data
}
export async function newUser(name, phone_number, email, password, role) {
  var response = await axios({
    url: `${baseUrl}/create_new_user?name=${name}&phone=${phone_number}&email=${email}&password=${password}&role=${role}&id_token=${VueCookies.get(
      'login',
    )}`,
  })
  return response.data
}
export async function getUserByEmail(email) {
  var response = await axios({
    url: `${baseUrl}/get_user_by_email?email=${email}`,
  })
  return response.data
}
export async function login(email, password) {
  var response = await axios({
    url: `${baseUrl}/login?email=${email}&password=${password}`,
  })
  return response.data
}
export async function moveOrder(back, status, order_id, sales_post) {
  var response = await axios({
    url: `${baseUrl}/move_order?back=${back}&status=${status}&order_id=${order_id}&sales_post=${sales_post}&id_token=${VueCookies.get(
      'login',
    )}`,
  })
  return response.data
}
