<template>
  <div class="text-center">
    <component :is="resolveLayout">
      <v-overlay style="z-index: 1000" :value="getLoadingState">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <router-view></router-view>
    </component>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import { getAuth } from 'firebase/auth'
import { signInWithCredential } from 'firebase/auth'
import axios from 'axios'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
  },
  computed: {
    getLoadingState() {
      return this.$store.state.loading
    },
  },
  mounted() {
    //set theme
    if (this.$cookies.isKey('theme') == false) {
      this.$vuetify.theme.dark = true
      this.$cookies.set('theme', true)
    } else {
      this.$vuetify.theme.dark = this.$cookies.get('theme') == 'true'
    }
    let user = getAuth().currentUser
    //check if user is logged in
    if (user == null) {
      //user not logged in, try to login from cached token
      if (this.$cookies.isKey('login')) {
        //token exists, log user in
        signInWithCredential(getAuth, this.$cookies.get(login))
      }
    }
  },
  setup() {
    const { route } = useRouter()

    const resolveLayout = computed(() => {
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  },
  data() {
    return {
      overlay: false,
    }
  },
}
</script>
