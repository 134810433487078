<template>
  <v-navigation-drawer :value="isDrawerOpen" app floating width="260" class="app-navigation-menu" :right="$vuetify.rtl"
    @input="(val) => $emit('update:is-drawer-open', val)">
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <v-img :src="require('@/assets/images/logos/logo.png')" max-height="50px" max-width="50px" alt="logo" contain
          eager class="app-logo me-3"></v-img>
        <v-slide-x-transition>
          <h2 class="app-title text--primary">Vlera Art</h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list expand shaped class="vertical-nav-menu-items pr-5">
      <nav-menu-link :title="translate('Dashboard')" :to="{ name: 'dashboard' }"
        :icon="icons.mdiChartBox"></nav-menu-link>
      <nav-menu-section-title title="Stocking"></nav-menu-section-title>

      <nav-menu-group title="Inventory" :icon="icons.mdiBagPersonal">
        <nav-menu-link title="Inventory" :to="{ name: 'stocks' }"></nav-menu-link>
        <nav-menu-link title="Stocks By Date" :to="{ name: 'stocks_by_date' }"></nav-menu-link>

        <nav-menu-link title="Add Checkin" :to="{ name: 'new_stock' }"></nav-menu-link>
        <nav-menu-link title="Adjust Inventory" :to="{ name: 'adjust_inventory' }"></nav-menu-link>
      </nav-menu-group>
      <nav-menu-group title="Orders" :icon="icons.mdiShopping">
        <nav-menu-link title="Orders" :to="{ name: 'orders' }"></nav-menu-link>

        <nav-menu-link title="Add Order" :to="{ name: 'new_order' }"></nav-menu-link>
      </nav-menu-group>
      <nav-menu-group title="Transfers" :icon="icons.mdiTruckFast">
        <nav-menu-link title="Transfers" :to="{ name: 'transfers' }"></nav-menu-link>
        <nav-menu-link title="Add Transfer" :to="{ name: 'new_transfer' }"></nav-menu-link>
      </nav-menu-group>
      <nav-menu-group title="Expenses" :icon="icons.mdiClipboardList">
        <nav-menu-link title="Expenses" :to="{ name: 'report_expenses' }"></nav-menu-link>
        <nav-menu-link title="Add Expense" :to="{ name: 'new_expense' }"></nav-menu-link>
      </nav-menu-group>
      <nav-menu-section-title title="Reports"></nav-menu-section-title>
      <nav-menu-link :title="translate('Profit/Loss Report')" :to="{ name: 'profit_loss' }"
        :icon="icons.mdiAccountGroup"></nav-menu-link>
      <nav-menu-link :title="translate('Employees')" :to="{ name: 'employees_report' }"
        :icon="icons.mdiAccountGroup"></nav-menu-link>
      <nav-menu-link :title="translate('Clients')" :to="{ name: 'clients' }"
        :icon="icons.mdiAccountGroup"></nav-menu-link>
      <nav-menu-link :title="translate('Checkins')" :to="{ name: 'report_checkins' }"
        :icon="icons.mdiClipboardList"></nav-menu-link>
      <nav-menu-link title="Transactions" :to="{ name: 'transactions' }"></nav-menu-link>

      <nav-menu-link :title="translate('Orders')" :to="{ name: 'report_orders' }"
        :icon="icons.mdiClipboardList"></nav-menu-link>
      <nav-menu-link :title="translate('Inv. Adjustment')" :to="{ name: 'inventory_adjustments' }"
        :icon="icons.mdiCubeScan"></nav-menu-link>
      <nav-menu-section-title title="Configuration"></nav-menu-section-title>
      <nav-menu-link :title="translate('Users')" :to="{ name: 'users' }" :icon="icons.mdiClipboardList"></nav-menu-link>
      <nav-menu-link :title="translate('Payment Methods')" :to="{ name: 'payment_methods' }"
        :icon="icons.mdiCreditCardOutline"></nav-menu-link>
      <nav-menu-link :title="translate('Origins')" :to="{ name: 'origins' }" :icon="icons.mdiSourceFork"></nav-menu-link>
      <nav-menu-link :title="translate('Products')" :to="{ name: 'products' }"
        :icon="icons.mdiClipboardList"></nav-menu-link>
      <nav-menu-link :title="translate('Expenses List')" :to="{ name: 'expense_types' }"
        :icon="icons.mdiClipboardList"></nav-menu-link>
      <nav-menu-link :title="translate('Magazines')" :to="{ name: 'magazines' }"
        :icon="icons.mdiHomeCityOutline"></nav-menu-link>
      <nav-menu-link :title="translate('Suppliers')" :to="{ name: 'suppliers' }" :icon="icons.mdiDolly"></nav-menu-link>
      <nav-menu-link :title="translate('Categories')" :to="{ name: 'categories' }" :icon="icons.mdiShape"></nav-menu-link>
      <nav-menu-link :title="translate('Machines')" :to="{ name: 'machines' }"
        :icon="icons.mdiPrinter3dNozzle"></nav-menu-link>
      <nav-menu-link :title="translate('Measurements')" :to="{ name: 'measurements' }"
        :icon="icons.mdiTapeMeasure"></nav-menu-link>
      <nav-menu-link :title="translate('Backup/Restore')" :to="{ name: 'backup_restore' }"
        :icon="icons.mdiCloudUpload"></nav-menu-link>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiChartBox,
  mdiBagPersonal,
  mdiShopping,
  mdiTruckFast,
  mdiClipboardList,
  mdiHomeCityOutline,
  mdiTapeMeasure,
  mdiBrush,
  mdiDolly,
  mdiTrashCan,
  mdiAccountGroup,
  mdiCubeScan,
  mdiCloudUpload,
  mdiScrewMachineFlatTop,
  mdiShape,
  mdiPrinter3dNozzle
} from "@mdi/js";
import NavMenuSectionTitle from "./components/NavMenuSectionTitle.vue";
import NavMenuGroup from "./components/NavMenuGroup.vue";
import NavMenuLink from "./components/NavMenuLink.vue";

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  computed: {
    isDrawerOpen() {
      return this.$store.state.isDrawerOpen;
    },
  },

  setup() {
    return {
      icons: {
        mdiCubeScan,
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiAccountGroup,
        mdiChartBox,
        mdiBagPersonal,
        mdiShopping,
        mdiTruckFast,
        mdiCloudUpload,
        mdiClipboardList,
        mdiHomeCityOutline,
        mdiDolly,
        mdiTapeMeasure,
        mdiBrush,
        mdiScrewMachineFlatTop,
        mdiTrashCan,
        mdiShape,
        mdiPrinter3dNozzle
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;

  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, "background");
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}
</style>
