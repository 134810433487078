require('./overrides.scss')

export default {
  theme: {
    themes: {
      light: {
        primary: '#161616',
        primaryLighter: '#e8e6e6',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#6C964C',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
      },
      dark: {
        primary: '#8047E9',
        primaryLighter: '#3b3461',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#6C964C',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
      },
    },
  },
}
