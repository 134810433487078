import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import 'leaflet/dist/leaflet.css'
import VueGeolocation from 'vue-browser-geolocation'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import JsonExcel from 'vue-json-excel'
const firebaseConfig = {
  apiKey: 'AIzaSyBVi318GEILUVLApEfVTrPbROu3oPpqOM4',
  authDomain: 'toy1-66387.firebaseapp.com',
  projectId: 'toy1-66387',
  storageBucket: 'toy1-66387.appspot.com',
  messagingSenderId: '886569674702',
  appId: '1:886569674702:web:da4a1beb56eb364cbd6d9f',
  measurementId: 'G-XBX96YD305',
}
const app = initializeApp(firebaseConfig)
Vue.use(VueGeolocation)
Vue.use(VueAxios, axios)
Vue.component('l-map', LMap)
Vue.component('l-tile-layer', LTileLayer)
Vue.component('l-marker', LMarker)
Vue.component('downloadExcel', JsonExcel)

getAuth().onAuthStateChanged(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
  }).$mount('#app')
})
export default app
