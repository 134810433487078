import Vue from 'vue'
import VueRouter from 'vue-router'
import { getAuth } from 'firebase/auth'
import axios from 'axios'
import { checkToken } from '@/ApiManager'
import app from '../main'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'index',
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/index.vue'),
    meta: { authorizedUsers: ['manager', 'designer', 'chief_designer', 'production'] },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/magazines',
    name: 'magazines',
    component: () => import('@/views/pages/magazines/magazines.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/expense_types/',
    name: 'expense_types',
    component: () => import('@/views/pages/expense_types/expense_types.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/new_magazine',
    name: 'new_magazine',
    component: () => import('@/views/pages/magazines/new_magazine.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/new_expense_type/',
    name: 'new_expense_type',
    component: () => import('@/views/pages/expense_types/new_expense_type.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/report_orders',
    name: 'report_orders',
    component: () => import('@/views/pages/reports/orders.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/report_checkins',
    name: 'report_checkins',
    component: () => import('@/views/pages/reports/checkins.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/new_expense',
    name: 'new_expense',
    component: () => import('@/views/pages/reports/new_expense.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/report_expenses',
    name: 'report_expenses',
    component: () => import('@/views/pages/reports/expenses.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/profit_loss',
    name: 'profit_loss',
    component: () => import('@/views/pages/reports/profit_loss.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/employees_report',
    name: 'employees_report',
    component: () => import('@/views/pages/reports/employees_report.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/new_service',
    name: 'new_service',
    component: () => import('@/views/pages/services/new_service.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/transfers',
    name: 'transfers',
    component: () => import('@/views/pages/transfers/transfers.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/new_transfer',
    name: 'new_transfer',
    component: () => import('@/views/pages/transfers/new_transfer.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/pages/products/products.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/edit_product',
    name: 'edit_product',
    component: () => import('@/views/pages/products/edit_product.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/new_product',
    name: 'new_product',
    component: () => import('@/views/pages/products/new_product.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/suppliers',
    name: 'suppliers',
    component: () => import('@/views/pages/suppliers/suppliers.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/new_supplier',
    name: 'new_supplier',
    component: () => import('@/views/pages/suppliers/new_supplier.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/origins',
    name: 'origins',
    component: () => import('@/views/pages/origins/origins.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/new_origin',
    name: 'new_origin',
    component: () => import('@/views/pages/origins/new_origin.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/origins',
    name: 'origins',
    component: () => import('@/views/pages/origins/origins.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/new_payment_method',
    name: 'new_payment_method',
    component: () => import('@/views/pages/payment_methods/new_payment_method.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/payment_methods',
    name: 'payment_methods',
    component: () => import('@/views/pages/payment_methods/payment_methods.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/new_measurement',
    name: 'new_measurement',
    component: () => import('@/views/pages/measurements/new_measurement.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/measurements',
    name: 'measurements',
    component: () => import('@/views/pages/measurements/measurements.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/new_category',
    name: 'new_category',
    component: () => import('@/views/pages/categories/new_category.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import('@/views/pages/categories/categories.vue'),
    meta: { authorizedUsers: ['manager'] },
  },

  {
    path: '/new_machine',
    name: 'new_machine',
    component: () => import('@/views/pages/machines/new_machine.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/machines',
    name: 'machines',
    component: () => import('@/views/pages/machines/machines.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/stocks',
    name: 'stocks',
    component: () => import('@/views/pages/stocks/stocks.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/new_stock',
    name: 'new_stock',
    component: () => import('@/views/pages/stocks/new_stock.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/adjust_inventory',
    name: 'adjust_inventory',
    component: () => import('@/views/pages/stocks/adjust_inventory.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/stocks_by_date',
    name: 'stocks_by_date',
    component: () => import('@/views/pages/stocks/stocks_by_date.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/pages/orders/orders.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: () => import('@/views/pages/orders/transactions.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/chief_designer_orders',
    name: 'chief_designer_orders',
    component: () => import('@/views/pages/orders/chief_designer/OrdersChief.vue'),
    meta: { authorizedUsers: ['manager', 'chief_designer'] },
  },
  {
    path: '/orders_designer',
    name: 'orders_designer',
    component: () => import('@/views/pages/orders/designer/OrdersDesigner.vue'),
    meta: { authorizedUsers: ['manager', 'designer'] },
  },
  {
    path: '/orders_production',
    name: 'orders_production',
    component: () => import('@/views/pages/orders/production/OrdersProduction.vue'),
    meta: { authorizedUsers: ['manager', 'production'] },
  },
  {
    path: '/orders_sales',
    name: 'orders_sales',
    component: () => import('@/views/pages/orders/sales/orders.vue'),
    meta: { authorizedUsers: ['manager', 'sales_agent'] },
  },

  {
    path: '/new_order',
    name: 'new_order',
    component: () => import('@/views/pages/orders/new_order.vue'),
    meta: { authorizedUsers: ['manager', 'sales_agent'] },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },

  {
    path: '/edit_magazine/',
    name: 'edit_magazine',
    component: () => import('@/views/pages/magazines/edit_magazine.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/edit_expense_type/',
    name: 'edit_expense_type',
    component: () => import('@/views/pages/expense_types/edit_expense_type.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/edit_order/',
    name: 'edit_order',
    component: () => import('@/views/pages/orders/edit_order.vue'),
    props: true,
    meta: { authorizedUsers: ['manager', 'sales_agent'] },
  },
  {
    path: '/users/',
    name: 'users',
    component: () => import('@/views/pages/users/users.vue'),
    props: true,
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/clients/',
    name: 'clients',
    component: () => import('@/views/pages/reports/clients.vue'),
    props: true,
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/inventory_adjustments/',
    name: 'inventory_adjustments',
    component: () => import('@/views/pages/reports/inventory_adjustments.vue'),
    props: true,
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/edit_service/:id',
    name: 'edit_service',
    component: () => import('@/views/pages/services/edit_service.vue'),
    props: true,
    meta: { authorizedUsers: ['manager'] },
  },
  {
    path: '/edit_transfer/:id',
    name: 'edit_transfer',
    component: () => import('@/views/pages/transfers/edit_transfer.vue'),
    props: true,
    meta: { authorizedUsers: ['manager'] },
  },

  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  {
    path: '/backup_restore',
    name: 'backup_restore',
    component: () => import('@/views/pages/backup_restore.vue'),
    meta: { authorizedUsers: ['manager'] },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
router.beforeEach((to, from, next) => {
  //if unauthorized, send user to login page
  if (to.name == 'login') {
    next()
  } else {
    if (localStorage.getItem('user') == null) next({ name: 'login' })
    if (
      to.meta.authorizedUsers != undefined &&
      to.meta.authorizedUsers.includes(JSON.parse(localStorage.getItem('user')).role)
    ) {
      next()
    } else {
      console.log('not authorized', to, to.meta.authorizedUsers, JSON.parse(localStorage.getItem('user')).role)
      next({ name: 'login' })
    }
  }
})
export default router
